<!-- Localized -->
<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table :columns="columns" :isLoading="loading" :rows="filteredRowsData" :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false}">
                <template #column-filter="{ column }">
                    <!-- <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                        <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div> -->
                    <dropdown
                            ref="setFilterRef"
                            reposition
                            searchable
                            class="filter-dropdown"
                            :placeholder="$t('actions.select')"
                            v-if="column?.filterOptions && column?.filterOptions?.enabled"
                            :options="getFilterOptions(column)"
                            @search="searchFilter(column, $event)"
                            @input="(value) => updateColumnFilters(column, value)"
                            :value="filters[column.field]"
                            :limit="1"
                            maxWidth="100%"
                            width="100px"
                            minWidth="100%"
                            :config="{ label: 'name', trackBy: 'id' }"
                            :multiple="false"
                            :taggable="false"
                        >
                    </dropdown>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'license_pack_name'" :class="[props.row.system_default && 'default hover-text']"
                        class="flex gap-3 pl-3">
                        <router-link :to="{
                            name: 'license-details',  
                            params: {id: props.row.license_pack_id}
                        }"
                            class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">{{ props.row.license_pack_name }}
                        </router-link>
                    </div>
                    <div v-if="props.column.field === 'tenant_users'" class="flex gap-3 justify-center">
                        <button :disabled="!props.row.tenant_user_count" @click="handleShowModal(props.row)"
                            class="flex disabled:opacity-50 flex-col gap-1 items-center relative cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current text-primary"
                                viewBox="0 0 448 512">
                                <path
                                    d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z" />
                            </svg>
                            <span
                                class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-primary-300 p-2 text-white-text">
                                {{ props.row.tenant_user_count }}
                            </span>
                        </button>
                    </div>
                    <div v-if="props.column.field == 'description'" class="flex justify-center">
                        {{ props.row.description }}                
                    </div>
                    <div v-if="props.column.field == 'alloted_license_count'" class="flex justify-center">
                        {{ props.row.alloted_license_count }}                
                    </div>
                    <div v-if="props.column.field == 'balanced_license_count'" class="flex justify-center">
                        {{ props.row.balanced_license_count }}                
                    </div>
                    <div v-if="props.column.field == 'validity'" class="flex justify-center">
                        {{ $t('pages.license.validity_days', { value : props.row.validity }) }}
                    </div>
                    <div v-if="props.column.field == 'tenant_license_count'" class="flex justify-center">
                        {{ props.row.tenant_license_count }}                
                    </div>
                    <div v-if="props.column.field == 'clients'" class="flex justify-center cursor-pointer" @click="assignedClients(props.row.clients)">
                            <div class="indicator">
                                <span class=" w-2 h-4 indicator-item badge badge-primary">{{props.row.client_count}}</span> 
                                <font-awesome-icon class="w-6  mx-2" icon= "user"/>
                            </div>
                        </div>
                </template>
                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <button 
            v-if="Object.values(filters).length"
            class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
            {{ $t('actions.clear_filters') }}
            </button>
            <ListModal modalTitle="Assigned Tenant Users" :isLoading="loadingStates.listModal" :lists="getLicenseTenantUsers"
                ref="license-analyst-modal" @handleFilterResult="handleFilterResult($event)">
                <template #item="{ item }">
                    <div class="flex justify-between gap-6 w-full">
                        <span class="truncate">
                            {{item.user_name}}
                        </span>
                        <button @click="removeUsers(item.user_id)"
                            class="h-6 w-6  rounded-full bg-gray-100 flex justify-center items-center text-gray-500 hover:bg-gray-200">
                            <font-awesome-icon icon="times" />
                        </button>
                    </div>
                </template>
                <template #footer>
                    <div class="flex pt-4 justify-between items-center">
                        <button class="btn normal-case px-4 min-h-0 gap-2 flex items-center rounded-xl" @click="handleCloseModal()">{{ $t('actions.cancel') }}</button>
                        <button class="btn bg-primary normal-case px-4 min-h-0 gap-2 flex items-center hover:bg-primary-600 rounded-xl" @click="saveRemovedTenantUsers()">{{ $t('actions.save') }}</button>
                    </div>
                </template>
    
            </ListModal>
            <Modal ref="assigned-clients" title='Assigned Clients' :showFooter='false'> 
                <template #content>
                    <div class="grid gap-2"> 
                        <span class="border-b text-bold" v-for="data in clientList" :key="data">{{data.client_name}}</span>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
import {getLicenseList} from "../services" 
import SubHeader from "@/components/SubHeader"
import Modal from "@/components/modal-content"
import { FormatDateNew } from "@/plugins/functions"
import dropdown from "@shared/components/dropdown-base";
import { uuid } from "vue-uuid";
const ListModal = () => import("@shared/components/listModal");
import Loader from "@shared/loader";
import axios from "@/axios";
export default {
    name: "taxList",
    components: {
        SubHeader,
        Loader,
        Modal,
        ListModal,
        dropdown,
    },
    data() {
        return {
            columns: [{
                label: "License Name",
                field: "license_pack_name",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "license_pack_name",
                },
            }, {
                label: "Description",
                field: "description",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "description",
                },
            }, {
                label: "Number of License",
                field: "tenant_license_count",
                sortable: false,
                // filterOptions: {
                //     enabled: true, // enable filter for this column
                //     multi: true,
                //     isLoading: false,
                //     query_key: "tenant_license_count",
                // },
            }, {
                label: "Assigned Clients",
                field: "clients",
                filter_type: "client_users",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "clients",
                },
            }, {
                label: "Assigned Users",
                field: "tenant_users",
                filter_type: "tenant_users",
                sortable: true,
                filterOptions: {
                    enabled: true, // enable filter for this column
                    multi: true,
                    isLoading: false,
                    query_key: "tenant_users",
                },
            },{
                label: "Allotted License",
                field: "alloted_license_count",
                sortable: false
            }, {
                label: "Balanced License",
                field: "balanced_license_count",
                sortable: false
            },  {
                label: "Validity",
                field: "validity",
                sortable: false
            },],
            rowsData: [],
            filteredRowsData: [],
            filters: {},
            loading: false,
            clientList: [],
            licenseTenantUsers: [],
            removedLicenseTenantUsers: [],
            loadingStates: {
                fetchingLicence: false,
                listModal: false,
                submit: false
            },
            searchValue: "",
        }
    },
    async mounted() {
        await this.setLicenseList()
    },
    computed: {
        getLicenseTenantUsers() {
            return this.licenseTenantUsers?.filter((el) => el.user_name?.toLowerCase()?.includes(this.searchValue.toLowerCase()));
        },
    },
    methods: {
        handleFilterResult(event) {
            this.searchValue = event;            
        },

        searchFilter(column, value) {
            this.$emit('search-filter', {column, value});
        },

        async setLicenseList()
        {
            this.loading = true
            const {data} = await getLicenseList()
            this.rowsData = data
            this.filteredRowsData = this.rowsData;
            this.loading = false

        },
        assignedClients(clients) {
            this.clientList = clients
            this.$refs["assigned-clients"].showModal();
        },
        async handleShowModal(row) {
            this.searchValue = ""
            this.selectedLicenseId = row.license_pack_id
            this.$refs["license-analyst-modal"].showListModal();
            this.getLicenseTenantData(row.tenant_users);
        },
        async getLicenseTenantData(users) {
            this.searchValue = ""
            this.loadingStates.listModal = true;
            try {
                this.licenseTenantUsers =  users.map(el => ({...el, id:el.user_id})) || []
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.listModal = false;
        },
        removeUsers(user_id){
            let removedUser = this.licenseTenantUsers.find(el => el.id == user_id );
            this.removedLicenseTenantUsers?.push(removedUser);
            this.licenseTenantUsers = this.licenseTenantUsers.filter(el => el.id !== user_id )
        },
        async saveRemovedTenantUsers(){
            try {
                if (this.removedLicenseTenantUsers?.length > 0) {
                    let user_ids = this.removedLicenseTenantUsers.map((el)=>el.id)
                    let payload = {
                        license_pack_id: this.selectedLicenseId,
                        user_ids: user_ids
                    }
                    const { data } = await axios.delete("/license-pack/tenant/remove-user", { data: payload });
                    this.selectedLicenseId = null
                    this.$toast.success(data.message ||  this.$t('pages.license.success_analyst_removed'));
                }
                this.handleCloseModal()
                await this.setLicenseList();
            } catch (error) {
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || this.$t('general_default_error'))
                } 
                else {
                    this.$toast.error(error?.request?.response?.data?.detail || this.$t('general_default_error'))
                }
            }
        },
        handleCloseModal() {
            this.searchValue = ""
            this.$refs["license-analyst-modal"].closeModal();
        },
        getFilterOptions(column){
            // get unique filter options array
            let filterData = []
            if (column?.filter_type == 'client_users' && this.rowsData?.length > 0) {
                this.rowsData.forEach((rowfilter) => {
                    rowfilter?.clients?.map((rowData) => {
                        if (!filterData?.some?.(data => data?.name == rowData?.client_name)) {
                            filterData?.push(
                                {
                                    id: rowData?.client_id,
                                    name: rowData?.client_name
                                }
                            )
                        }
                    })
                })
            } else if (column?.filter_type == 'tenant_users' && this.rowsData?.length > 0) {
                this.rowsData.forEach((rowfilter) => {
                    rowfilter?.tenant_users?.map((rowData) => {
                        if (!filterData?.some?.(data => data?.name == rowData?.user_name)) {
                            filterData?.push(
                                {
                                    id: rowData?.user_id,
                                    name: rowData?.user_name
                                }
                            )
                        }
                    })
                })
            } else {
                filterData = this.rowsData
                .filter((row) => row[column.field])
                .map((row) => (
                    { 
                        id: row.field_type_id ?? uuid.v4(), 
                        label: row[column.field],
                        name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
                    }
                )
                )
                if (filterData && filterData?.length > 0) {
                    const uniqueMap = new Map();
                    filterData = filterData.forEach(item => {
                      uniqueMap.set(item.name, item);
                    });
                    return Array.from(uniqueMap.values());
                }
            }
            return filterData;
        },
        updateColumnFilters(column, value){
            // this.currentPageData.page = 1;
            if (column?.filter_type == 'date') {
                this.filters[column.field]  =  value.label ? [value.label] : [] ;
            } else {
                this.filters[column.field]  =  value.name ? [value.name] : [] ;
            }
            let result = this.rowsData;
            for (let filter in this.filters) {
                if (this.filters[filter]?.length) {
                    if (column?.filter_type == 'client_users') {
                        result = result?.filter((el) => el?.clients?.some(clnt => clnt?.client_name == value?.name))
                    } else if (column?.filter_type == 'tenant_users') {
                        result = result?.filter((el) => el?.tenant_users?.some(tnt => tnt?.user_name == value?.name))
                    } else {
                        result = result.filter((el) => this.filters[filter].includes(el[filter]));
                    }
                }
            }
            this.filteredRowsData = result;
        },
        clearFilters() {
            this.filters = {};
            this.filteredRowsData = this.rowsData;
        },
}

}
</script>

<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>


























